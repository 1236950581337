import Vue from "vue";

/*TITLE*/
document.title = "TQ-Vilablareix | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "TQ-Vilablareix";
Vue.prototype.$subtitle = "Obra nueva en Vilablareix";

/*INTRO*/
Vue.prototype.$promoter = "TQ-Vilablareix";
Vue.prototype.$introSubtitle = "Obra nueva en Vilablareix";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_ac6-natural-1l-michigan-clear_rgb--20230124040142.jpg";
Vue.prototype.$image_kitchen = "cocina_natural-1l-michigan-clear-etimoe-night-xtone-bottega-antracita_rgb--20230124040154.jpg";
Vue.prototype.$image_bath1 = "banyo-1_-natural-1l-michigan-clear-glaciar_cmyk--20230124040117.jpg";
Vue.prototype.$image_bath2 = "banyo-2_-natural-1l-michigan-clear-glaciar_rgb--20230124040132.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_ac6-natural-1l-michigan-clear_rgb--20230124040142.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina_natural-1l-michigan-clear-etimoe-night-xtone-bottega-antracita_rgb--20230124040154.jpg",
  },
  {
    src: "cocina_natural-1l-michigan-clear-bison-xtone-bottega-caliza_rgb--20230124040100.jpg",
  },
  {
    src: "cocina_natural-1l-michigan-blanco-nubol-bottega-acero_rgb--20230124040108.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-1_-natural-1l-michigan-clear-glaciar_cmyk--20230124040117.jpg",
  },
  {
    src: "banyo-1_-bottega-acero_cmyk--20230124040125.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2_-natural-1l-michigan-clear-glaciar_rgb--20230124040132.jpg",
  },
  {
    src: "banyo-2_-bottega-acero_rgb--20230124040138.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/VILABLAREIX/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/zyro6y5vkoM";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=vilablareix%20girona%2017180",
    text: "Vilablareix, Girona, 17180",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=17180%20vilablareix%20girona",
    text: "17180 Vilablareix, Girona",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:972219758",
    text: "972219758",
  },
];
